import React from "react"
import { Link } from "gatsby"
import {
  GridBox,
  Standalone,
  Text,
  Title,
} from "@thedgbrt/logology-ui-components"
import SEO from "../../components/seo"

const TosPage = () => (
  <>
    <SEO title="Conditions générales de vente" />
    <GridBox type={5} colSpans={[[3, 8], [3, 8], [3, 12]]} withBgContainer>
      <Title standalone>Conditions générales de vente</Title>
    </GridBox>

    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Text italic>
          LOGOLOGY, société par actions simplifiée, au capital de 5.000,00
          euros, immatriculée au Registre du commerce et des sociétés de Lille
          métropole sous le numéro 879 396 521, dont le siège social est situé
          11 place Simon Vollant 59800 Lille, représentée par Dagobert Renouf,
          son gérant et dont l’adresse email est{" "}
          <a href="mailto:support@logology.co">support@logology.co</a>, ci-après
          désignée « Logology ».
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Préambule</Title>
        <Text italic>
          Logology est une société spécialisée dans la création et la
          commercialisation de logos. Elle conçoit, crée et développe des
          identités visuelles fortes qui permettent à ses clients de se
          démarquer. Logology accorde une importance particulière à la
          protection et à la défense de sa propriété intellectuelle et de son
          savoir-faire. Le Client s’est rapproché de Logology afin d’acquérir un
          Logo, visible sur le site internet de Logology{" "}
          <Link to="/">www.logology.co</Link>, au prix mentionné sur ce site.
          Deux types de logos sont présentés sur ce site internet :
          <ul>
            <li>Logo et licence de droits: start</li>
            <li>Logo et licence de droits: grow</li>
            <li>Logo et licence de droits: establish</li>
          </ul>
        </Text>
        <Text italic>
          Les présentes conditions encadrent la relation entre Logology et le
          Client. Elles constituent l'intégralité des obligations des Parties.
          Ils ne peuvent être modifiés que d’un commun accord entre les Parties
          par écrit. L’acquisition d’un Logo par le biais du site internet de
          Logology <Link to="/">www.logology.co</Link>, implique l’acceptation
          pleine et entière par le Client des présentes Conditions Générales de
          Vente. Celles-ci prévaudront sur toutes autres conditions générales ou
          particulières du Client. Logology se réserve la possibilité de
          modifier à tout moment ces Conditions Générales de Vente. Il est,
          toutefois, convenu que les Conditions applicables au Client seront
          celles en vigueur à la date de sa commande sur le Site.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 1. Objet du contrat</Title>
        <Text italic>
          Le présent contrat a pour objet de déterminer les conditions dans
          lesquelles le Client acquiert le Logo, présenté sur le site internet
          de Logology <Link to="/">www.logology.co</Link>
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 2. Obligations des Parties</Title>
        <Title level={5}>2.1. Obligations de Logology</Title>
        <Text italic>
          La prestation de Logology consiste à concevoir et commercialiser un
          Logo original, via le site internet de Logology{" "}
          <Link to="/">www.logology.co</Link>, de manière professionnelle,
          compte tenu de l’état de l’art et en fonction des usages de la
          profession.
        </Text>
        <Title level={5}>2.2. Obligations du Client</Title>
        <Text italic>
          Le Client est responsable du respect des législations propres à son
          activité. Le Client s’engage à utiliser le Logo commandé et payé
          intégralement, dans des conditions normales et conformément à
          l’article 5 des présentes Conditions générales de vente. Le Client
          s’interdit de reproduire, exploiter ou modifier tout logo qui lui
          serait proposé par Logology, et qui n’aurait pas été accepté et payé
          par lui.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 3. Responsabilité</Title>
        <Text italic>
          Logology est soumise à une obligation de moyens. La réparation du
          préjudice subi par le Client du fait du présent contrat, par Logology,
          ne saurait excéder le prix du Logo. Logology peut s’exonérer de tout
          ou partie de sa responsabilité en apportant la preuve que
          l’inexécution ou la mauvaise exécution du contrat est imputable, soit
          au Client, soit au fait, imprévisible et insurmontable, d’un tiers au
          contrat, soit à un cas de force majeure. Logology ne saurait être
          responsable en cas de bug informatique intervenant lors de sa
          commande, lié à la connexion internet du Client.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 4. Garanties de Logology</Title>
        <Text italic>
          Logology garantit au Client la jouissance paisible des droits qui lui
          sont consentis contre tous troubles, revendications ou évictions
          quelconques. Elle garantit le Client contre toute action judiciaire
          relative au Logo vendu, dans la limite du prix du contrat, sous
          réserve d’en avoir été informée par courrier recommandé dans les
          quatre jours ouvrés suivants la réception d’une mise en demeure ou
          d’un acte introductif d’instance par le Client. Logology est alors
          seule maître de la manière de conduire l’action. Le Client doit
          fournir les informations nécessaires pour mener à bien sa défense ou
          parvenir à un accord transactionnel. En cas de prestation de naming
          consistant pour Logology à proposer un nom de produit ou d’entité au
          Client, ou en cas de Logo devant être adapté à une dénomination
          choisie initialement par le Client seul, le Client fait son affaire
          personnelle de la recherche exhaustive juridique de la disponibilité
          dudit nom en vue de son exploitation paisible et d’un dépôt de marque
          ; en sa qualité de professionnelle, Logology pouvant le mettre en
          relation avec un avocat spécialisé en propriété intellectuelle. Tous
          les logos fournis par Logology bénéficient de la garantie légale
          prévue aux articles 1641 et suivants du Code civil. En outre, le
          Client bénéficie également de la garantie de conformité des produits,
          prévue par les articles L. 211-4 et suivants du Code de la
          consommation. Toutes les réclamations au titre de cet article doivent
          s’effectuer par voie postale ou par mail aux adresses susvisées en
          page 1.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 5. Propriété intellectuelle</Title>
        <Title level={5}>
          5.1. Droits acquis ou détenus avant la conclusion du Contrat
        </Title>
        <Text italic>
          Logology demeure propriétaire de tous éléments protégés par des droits
          de propriété intellectuelle acquis et/ou détenus antérieurement à la
          conclusion du présent contrat et le paiement du prix du Logo, ainsi
          que des droits y afférents. Le Client n’est donc pas autorisé à les
          exploiter, représenter ou reproduire, en tout ou partie, sans
          l’autorisation écrite préalable de Logology, donnée à la seule
          discrétion de cette dernière. Le contrat ne transfère aucun titre de
          propriété, ni ne confère aucune cession ou concession de droits de
          propriété intellectuelle existants avant la conclusion des présentes
          et le paiement du prix du Logo. Ainsi, le Client s’interdit de
          reproduire, exploiter ou modifier tout logo qui lui serait proposé par
          Logology, et qui n’aurait pas été accepté et payé entièrement par lui.
        </Text>
        <Title level={5}>
          5.2. Licence des droits de propriété intellectuelle afférents au Logo
          commandé, pour le produit intitulé « Logo et licence de droits: start
          »
        </Title>
        <Text italic>
          <ul>
            <li>
              Logology est titulaire des droits d'auteur du Logo original,
              commandé. Elle est investie de l'intégralité des droits d'auteur
              sur celui-ci, conformément au Code de la propriété intellectuelle.
              Par ailleurs, concernant les éventuels composants du Logo que
              Logology aurait apportés et dont elle ne serait pas l'auteur,
              Logology déclare avoir obtenu tous les droits nécessaires à leur
              exploitation, leur reproduction et à l’exploitation par le Client
              dans les conditions ci-dessous.
            </li>
            <li>
              Lorsque le Logo sera achevé, validé par le Client, et le prix
              intégralement payé, Logology concèdera certains droits
              patrimoniaux au Client limitativement énumérés ci-après, pour la
              durée des droits d’auteur, à compter du paiement du prix intégral,
              dans le cadre d’une licence non exclusive de droits patrimoniaux.
              Ce droit comprend :
            </li>
            <li>
              le droit de reproduction : ce droit comporte le droit de
              reproduire et d'utiliser notamment à titre commercial,
              promotionnel et publicitaire, directement ou indirectement par
              tous procédés techniques, sur tous supports écrits, papier
              (catalogues, prospectus, mailing...), plastique, audiovisuel,
              numérique magnétique, optique, électronique ou multimédia et sur
              tout réseau de télécommunication privatif ou ouvert, national ou
              international (et notamment Internet, Intranets et Extranets), le
              Logo original (en tout ou en partie), objet de la présente
              licence, en nombre illimité ; ce droit comporte également le droit
              pour le Client de confier l'exécution de cette exploitation à
              toute personne physique ou morale de son choix. Le droit de
              reproduction comprend notamment le droit de numériser, mettre en
              ligne, télécharger.
            </li>
            <li>
              le droit de représentation : ce droit comporte le droit de
              représenter, de rendre accessible, de diffuser ou de communiquer
              au public d'une quelconque façon, directement ou indirectement et
              par tout procédé de télécommunication et d'images analogiques ou
              numérique, par voie hertzienne, par câble ou par satellite de
              réception directe ou non, par tous services "on line" et
              assimilés, tout ou partie du Logo original pour toute utilisation
              qui pourrait être faite par le Client, soit à son profit, soit
              pour le compte d'un tiers.
            </li>
          </ul>
        </Text>
        <Text italic>
          Il ne comprend pas le droit de commercialisation, de distribution, de
          modification, de correction, d'adaptation, de mise à disposition,
          d’extraction, de transfert, de donner en licence, et de dépôt de
          marque. Logology pourra utiliser à son profit, ou au profit de tiers,
          le Logo dont les droits sont ainsi concédés. Compte tenu du caractère
          universel d’internet, les droits patrimoniaux ainsi concédés le sont
          pour le monde entier. En raison du type d'œuvre pour lesquels sont
          concédés les droits patrimoniaux et de leur destination, la
          rémunération de l'auteur au titre de la licence sera forfaitaire et
          comprise dans le prix global. Les fichiers source ne seront pas remis
          au Client par Logology, après paiement du prix intégral mentionné sur
          le site internet de Logology www.logology.co
        </Text>

        <Title level={5}>
          5.3 Licence des droits de propriété intellectuelle afférents au Logo
          commandé, pour le produit intitulé « Logo et licence de droits: grow »
        </Title>
        <Text italic>
          <ul>
            <li>
              Logology est titulaire des droits d'auteur du Logo original,
              commandé. Elle est investie de l'intégralité des droits d'auteur
              sur celui-ci, conformément au Code de la propriété intellectuelle.
              Par ailleurs, concernant les éventuels composants du Logo que
              Logology aurait apportés et dont elle ne serait pas l'auteur,
              Logology déclare avoir obtenu tous les droits nécessaires à leur
              exploitation, leur reproduction et à l’exploitation par le Client
              dans les conditions ci-dessous.
            </li>
            <li>
              Lorsque le Logo sera achevé, validé par le Client, et le prix
              intégralement payé, Logology concèdera certains droits
              patrimoniaux au Client limitativement énumérés ci-après, pour la
              durée des droits d’auteur, à compter du paiement du prix intégral,
              dans le cadre d’une licence non exclusive de droits patrimoniaux.
              Ce droit comprend :
            </li>
            <li>
              le droit de reproduction : ce droit comporte le droit de
              reproduire et d'utiliser notamment à titre commercial,
              promotionnel et publicitaire, directement ou indirectement par
              tous procédés techniques, sur tous supports écrits, papier
              (catalogues, prospectus, mailing...), plastique, audiovisuel,
              numérique magnétique, optique, électronique ou multimédia et sur
              tout réseau de télécommunication privatif ou ouvert, national ou
              international (et notamment Internet, Intranets et Extranets), le
              Logo original (en tout ou en partie), objet de la présente
              licence, en nombre illimité ; ce droit comporte également le droit
              pour le Client de confier l'exécution de cette exploitation à
              toute personne physique ou morale de son choix. Le droit de
              reproduction comprend notamment le droit de numériser, mettre en
              ligne, télécharger.
            </li>
            <li>
              le droit de représentation : ce droit comporte le droit de
              représenter, de rendre accessible, de diffuser ou de communiquer
              au public d'une quelconque façon, directement ou indirectement et
              par tout procédé de télécommunication et d'images analogiques ou
              numérique, par voie hertzienne, par câble ou par satellite de
              réception directe ou non, par tous services "on line" et
              assimilés, tout ou partie du Logo original pour toute utilisation
              qui pourrait être faite par le Client, soit à son profit, soit
              pour le compte d'un tiers.
            </li>
            <li>
              le droit de modification : ce droit comporte le droit de modifier,
              corriger ou d'adapter le Logo de quelque manière jugée appropriée
              par le client.
            </li>
          </ul>
        </Text>
        <Text italic>
          Il ne comprend pas le droit de commercialisation, de distribution, de
          mise à disposition, d’extraction, de transfert, de donner en licence,
          et de dépôt de marque. Logology pourra utiliser à son profit, ou au
          profit de tiers, le Logo dont les droits sont ainsi concédés. Compte
          tenu du caractère universel d’internet, les droits patrimoniaux ainsi
          concédés le sont pour le monde entier. En raison du type d'œuvre pour
          lesquels sont concédés les droits patrimoniaux et de leur destination,
          la rémunération de l'auteur au titre de la licence sera forfaitaire et
          comprise dans le prix global.
        </Text>

        <Title level={5}>
          5.4. Cession des droits de propriété intellectuelle afférents au Logo
          commandé, pour le produit intitulé « Logo et cession de droits:
          establish »
        </Title>

        <Text italic>
          <ul>
            <li>
              Logology est titulaire des droits d'auteur du Logo original,
              commandé. Elle est investie de l'intégralité des droits d'auteur
              sur celui-ci, conformément au Code de la propriété intellectuelle.
              Par ailleurs, concernant les éventuels composants du Logo que
              Logology aurait apportés et dont elle ne serait pas l'auteur,
              Logology déclare avoir obtenu tous les droits nécessaires à leur
              exploitation, leur reproduction et à leur cession au Client dans
              les conditions ci-dessous.
            </li>
            <li>
              Lorsque le Logo sera achevé, validé par le Client, et le prix
              intégralement payé, Logology cèdera les droits patrimoniaux
              d’auteur, ci-après énumérés, au Client pour la durée des droits
              d’auteur.
            </li>
            <li>
              La cession en totalité et à titre exclusif sans exception ni
              réserve au Client porte notamment sur les droits d’exploitation,
              de reproduction, de représentation, d'usage, de réutilisation, de
              dépôt de marque, ainsi que l’ensemble des garanties de droit et de
              fait associées, en vue d’une exploitation directe et/ou indirecte
              par le Client, pour toute destination quelle qu’elle soit.
            </li>
          </ul>
        </Text>
        <Text italic>
          La cession porte sur l’ensemble des droits patrimoniaux, à savoir :
          <ul>
            <li>
              le droit de reproduction : ce droit comporte le droit de
              reproduire et d'utiliser notamment à titre commercial,
              promotionnel et publicitaire, directement ou indirectement par
              tous procédés techniques, sur tous supports écrits, papier
              (catalogues, prospectus, mailing...), plastique, audiovisuel,
              numérique magnétique, optique, électronique ou multimédia et sur
              tout réseau de télécommunication privatif ou ouvert, national ou
              international (et notamment Internet, Intranets et Extranets), le
              Logo original (en tout ou en partie), objet de la présente
              cession, en nombre illimité ; ce droit comporte également le droit
              pour le Client de confier l'exécution de cette exploitation à
              toute personne physique ou morale de son choix. Le droit de
              reproduction comprend notamment le droit de numériser, mettre en
              ligne, télécharger.
            </li>
            <li>
              le droit de représentation : ce droit comporte le droit de
              représenter, de rendre accessible, de diffuser ou de communiquer
              au public d'une quelconque façon, directement ou indirectement et
              par tout procédé de télécommunication et d'images analogiques ou
              numérique, par voie hertzienne, par câble ou par satellite de
              réception directe ou non, par tous services "on line" et
              assimilés, tout ou partie du Logo original pour toute utilisation
              qui pourrait être faite par le Client, soit à son profit, soit
              pour le compte d'un tiers
            </li>
          </ul>
        </Text>
        <Text italic>
          Il ne comprend pas le droit de commercialisation, de distribution, de
          mise à disposition, d’extraction, de transfert etde donner en licence.
          Logology ne pourra pas utiliser, à son profit ou au profit de tiers,
          le Logo dont les droits sont ainsi cédés, sauf à titre de référence
          professionnelle ou commerciale. Compte tenu du caractère universel
          d’internet, les droits patrimoniaux ainsi cédés le sont pour le monde
          entier. En raison du type d'œuvre pour lesquels sont cédés les droits
          patrimoniaux et de sa destination, la rémunération de l'auteur au
          titre de la cession sera forfaitaire et comprise dans le prix global.
        </Text>
        <Text italic>
          Les fichiers source sont remis au Client par Logology, après paiement
          du prix intégral mentionné sur le site internet de Logology
          www.logology.co
        </Text>

        <Title level={5}>5.5. Mention du nom de Logology</Title>
        <Text italic>
          Le nom de Logology sera cité sur les créations proposées par elle. Le
          Client s’engage à faire ses meilleurs efforts pour mentionner le nom «
          Logology » sur tous ses supports de communication et notamment son
          site internet.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 6. Prix et modalités de paiement</Title>
        <Text italic>
          Le Client versera à Logology le prix mentionné au sein du site
          internet de Logology : <Link to="/">www.logology.co</Link> Cette somme
          couvre l’acquisition définitive par le Client des droits de propriété
          intellectuelle portant sur les créations de Logology, dans les
          conditions prévues à l’article 5. Le prix de vente est exigible dès la
          commande. Le paiement sera effectué par carte bleue ou virement. Le
          numéro de carte bancaire du Client est uniquement dirigé vers les
          serveurs de la banque, dans un environnement sécurisé. Les prix
          indiqués par Logology au moment de la commande sont libellés en Euros
          TTC, en fonction de la TVA au taux en vigueur au jour de la commande.
          Tout changement de taux sera répercuté sans délai sur le prix des
          logos proposés. Logology se réserve la possibilité de modifier ses
          prix à tout moment, étant toutefois entendu que les prix présentés sur
          le site de Logology au jour de la commande seront seuls applicables au
          Client. Les prix indiqués comprennent les frais de traitement de la
          commande.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 7. Processus de commande</Title>
        <Text italic>
          Le Client souhaitant passer une commande sur le Site de Logology doit
          :
          <ul>
            <li>
              créer son compte en renseignant les informations demandées sur le
              site internet de Logology <Link to="/">www.logology.co</Link> (à
              savoir : nom, prénom, adresse postale, adresse électronique,
              numéro de téléphone).
            </li>
            <li>
              consulter les logos et ajouter à son panier le Logo souhaité
            </li>
            <li>valider le panier</li>
            <li>valider la commande</li>
            <li>choisir et valider son mode de paiement</li>
          </ul>
        </Text>
        <Text italic>
          Il appartient au Client de s’assurer de l’exactitude des informations
          fournies, dont il est seul responsable. Logology communiquera sans
          délai au Client par courrier électronique la confirmation de la
          commande enregistrée. La date limite de livraison sera précisée dans
          le courrier électronique de confirmation de la commande. L’ensemble
          des données fournies et la confirmation enregistrée valent preuve de
          la transaction. Logology procédera à l’archivage des commandes et
          confirmations de commandes sur un support fiable et durable
          constituant une copie fidèle, conformément aux dispositions du Code
          civil. Les registres informatisés de Logology seront considérés par
          les Parties comme preuve des communications, commandes, paiements et
          transactions intervenues entre les Parties. Logology a souscrit un
          contrat de vente à distance auprès de son organisme bancaire. La
          transaction bancaire est sécurisée et, en aucune façon, Logology ne
          conserve d’informations bancaires sur le Client. Logology se réserve
          le droit de refuser une commande présentant un caractère anormal,
          notamment au regard des quantités commandées. Logology informe le
          Client que ses données à caractère personnel sont enregistrées et
          qu’elle a effectué les démarches préalables auprès de la CNIL
          concernant le traitement de ces données, conformément aux dispositions
          de la loi Informatique et Libertés n° 78-17 du 6 janvier 1978 et au
          RGPD.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 8. Sous-traitance</Title>
        <Text italic>
          Le Client autorise Logology à recourir à la sous-traitance.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 9. Assurances</Title>
        <Text italic>
          Chacune des Parties déclare être titulaire d’une police d’assurance
          souscrite auprès d’une compagnie adaptée à ses prestations, couvrant
          sa responsabilité civile professionnelle et garantissant les
          conséquences pécuniaires de cette responsabilité provenant de dommages
          corporels, matériels, immatériels consécutifs ou non à un dommage
          matériel ou corporel pour un montant de garantie suffisant au regard
          des engagements pris dans le cadre du présent contrat. Si une Parties
          en fait la demande, l’autre Partie devra lui remettre une copie des
          justificatifs démontrant que sa police d'assurances est à jour.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 10. Références et communication</Title>
        <Text italic>
          Conformément aux pratiques de la profession, le Client concède à
          Logology le droit de communiquer sur la collaboration entre les
          Parties et le nom du Client, par tout moyen (newsletter, site
          internet, blog, réseaux sociaux, conférences, presse...) dans le cadre
          de la promotion commerciale et/ou technique (par la réalisation
          d’études de cas) de Logology.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>Article 11. Résiliation</Title>
        <Text italic>
          En cas de manquement grave du Client aux obligations du présent
          contrat non réparé dans un délai de 10 (dix) jours à compter de la
          lettre recommandée avec accusé de réception notifiant ledit
          manquement, le présent contrat sera résilié de plein droit aux torts
          du Client, sans préjudice des dommages et intérêts dont Logology
          pourrait prétendre. Le Client n’aurait donc plus la possibilité
          d’exploiter le Logo. Le fait pour une des Parties de ne pas se
          prévaloir d'un manquement par l'autre Partie à l'une quelconque des
          obligations visées dans les présentes ne saurait être interprété pour
          l'avenir comme une renonciation à l'obligation en cause.
        </Text>
      </Standalone>
    </GridBox>
    <GridBox type={5} colSpans={[[2, 8], [2, 8], [2, 12]]} withBgContainer>
      <Standalone>
        <Title level={3}>
          Article 12. Loi applicable et juridiction compétente
        </Title>
        <Text italic>
          e présent Contrat est soumis à la loi française. Les Parties
          conviennent de s’efforcer de régler à l’amiable tous les différends
          nés de la conclusion, de l’interprétation, de l’exécution ou de la
          cessation des présentes.
        </Text>
        <Text italic>
          A défaut d’accord amiable, tout litige entre les Parties, relatif à la
          conclusion, l’interprétation, l’exécution ou, la cession du Contrat
          pour quelque cause que ce soit, ainsi que toutes suites qui pourrait
          en résulter, sera soumis aux tribunaux du ressort de Lille métropole,
          nonobstant appel en garantie ou pluralité de défendeurs, y compris
          dans le cas de procédures d’urgence ou conservatoires, en référé ou
          par requête.
        </Text>
      </Standalone>
    </GridBox>
  </>
)

export default TosPage
